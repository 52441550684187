import PropTypes from "prop-types";
import React from "react";
import { ficheProduitPropTypes, resolutionPropType } from "app/utils/propTypes";
import HomeDesktop from "@brand/Home/desktop/HomeDesktop";
import HomeMobile from "@brand/Home/mobile/HomeMobile";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { useRouteMatch } from "react-router-dom";

const Home = props => {
	const { resolution } = props;

	const loginMatch = useRouteMatch({
		path: "/home/login",
		sensitive: true,
	});

	const signupMatch = useRouteMatch({
		path: "/home/signup",
		sensitive: true,
	});

	return (
		<React.Fragment>
			{loginMatch && <HeadMetadata page="login" />}
			{signupMatch && <HeadMetadata page="signup" />}

			{resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM ? (
				<HomeMobile {...props} />
			) : (
				<HomeDesktop {...props} />
			)}
		</React.Fragment>
	);
};

Home.propTypes = {
	match: PropTypes.object,
	auth: PropTypes.element,
	shop: PropTypes.string,
	onGoingProductCount: PropTypes.number,
	resolution: resolutionPropType,
	products: PropTypes.arrayOf(ficheProduitPropTypes),
	brandDisplayName: PropTypes.string,
};

Home.defaultProps = {
	match: {},
};

export default React.memo(Home);
