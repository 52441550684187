import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Home from "app/pages/Home/Home";
import { fetchProducts } from "app/pages/Listing/productActionCreators";
import { ficheProduitPropTypes } from "app/utils/propTypes";
import { getProductsForHome } from "app/pages/Listing/productsSelector";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { sendTagOnHomePageLoad } from "app/utils/analytics";
import { getHomeImages } from "app/reducers/partnerSelector";
import { getCurrentShop } from "app/reducers/shopSelector";
import { getPartnerCode } from "app/reducers/partnerSelector";
import { getBrandDisplayName } from "app/reducers/partnerSelector";

const HomeContainer = props => {
	const { fetchProducts, productsVisibility, shop, partnerCode } = props;

	useEffect(() => {
		fetchProducts({ productsVisibility, shop, partnerCode });
		sendTagOnHomePageLoad();
	}, [productsVisibility, shop, partnerCode]);

	return <Home {...props} />;
};

HomeContainer.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
	resolution: PropTypes.string,
	route: PropTypes.object,
	products: PropTypes.arrayOf(ficheProduitPropTypes),
	fetchProducts: PropTypes.func,
	acceptCookyPolicy: PropTypes.func,
	productsVisibility: PropTypes.string,
	shop: PropTypes.string,
	partnerCode: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		onGoingProductCount: state.products.length,
		products: getProductsForHome(state),
		resolution: state.resolution,
		brand: state.brand.code,
		home: getHomeImages(state),
		shop: getCurrentShop(state),
		partnerCode: getPartnerCode(state),
		productsVisibility: state.productsVisibility,
		brandDisplayName: getBrandDisplayName(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchProducts }, dispatch);
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomeContainer)
);
